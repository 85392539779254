import React, { useCallback, useState, Fragment, useEffect, FormEvent, useContext } from 'react'
import {
  Slide,
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  TextField,
  MenuItem,
  makeStyles,
  Theme,
  Paper,
  InputBase,
  IconButton,
  Icon,
  LinearProgress,
  Typography,
  Grid,
  TableCell, Table, TableBody, TableRow, TableHead
} from '@material-ui/core'
import DialogTitle from '../DialogTitle'
import { Receipt, Recipe } from '../../types'
import Autocomplete from '../Autocomplete'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import Autosuggest from 'react-autosuggest'
import { AppContext } from '../../App'
import { TransitionProps } from '@material-ui/core/transitions/transition'
import NumericTextField from '../NumericTextField'
import { MobileDatePicker } from '@material-ui/pickers'
import {getOne} from '../../HTTPClients/RecipeApp/receipts';
import {getAll as getRecipes} from '../../HTTPClients/RecipeApp/recipes/recipes';
import {getAll as getProjects} from '../../HTTPClients/RecipeApp/projects';
import {getAll as getCustomers} from '../../HTTPClients/RecipeApp/customers';
import moment from 'moment'
import {Alert} from "@material-ui/lab";
import {Settings} from "../../config/settings";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    position: 'relative'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  progress: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    background: 'none'
  }
}))

const SearchField: React.FC<{ searchValue: string, placeholder: string, onChange?: (value: string) => void, onSearch: (value: string) => Promise<void> }> = ({ searchValue, placeholder, onChange, onSearch }) => {
  const [value, setValue] = useState(searchValue)
  const [searching, setSearching] = useState(false)
  const classes = useStyles()
  const handleChange = useCallback((value: string) => {
    setValue(value)
    onChange && onChange(value)
  }, [onChange])
  const handleSearch = useCallback(async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setSearching(true)
    await onSearch(value)
    setSearching(false)
  }, [value, onSearch])

  return (
    <form onSubmit={handleSearch}>
      <Paper className={classes.root} elevation={4}>
        <InputBase
          className={classes.input}
          placeholder={placeholder}
          value={searchValue ? searchValue : value}
          onChange={e => handleChange(e.target.value)}
        />
        <IconButton disabled={value === ''} type="submit" className={classes.iconButton}>
          <Icon>search</Icon>
        </IconButton>
        {searching && <LinearProgress variant="indeterminate" className={classes.progress} />}
      </Paper>
    </form>
  )
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
})

const getSuggestionValue = (recipe: Recipe) => recipe.recipeName
const getCustomerSuggestionValue = (customer: any) => customer.name
const getProjectSuggestionValue = (project: any) => project.name

type AddSampleDialogProps = {
  receipts: Receipt[],
  open: boolean,
  onAdd?: (receipt: Receipt, createReceipt: boolean) => void,
  onClose: () => void
}

const AddSampleDialog: React.FC<AddSampleDialogProps> = ({ receipts, open, onAdd, onClose }) => {
  const [suggestions, setSuggestions] = useState([] as Receipt[])
  const [isSearchResult, setIsSearchResult] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [manual, setManual] = useState(false)
  const [data, setData] = useState({ testDate: new Date() } as {
    receiptId?: number, recipe?: Recipe, customer?: string, project?: string, customer_id?: number, project_id?: number, order_amount?: number, amount?: number, testDate: Date })
  const [editOnly, setEditOnly]= useState(false)
  const [recipes, setRecipes]=useState([] as Recipe[])
  const [projects, setProjects]=useState([] as any[])
  const [customers, setCustomers]=useState([] as any[])
  const [recipeSearch, setRecipeSearch]=useState('')
  const [recipeError, setRecipeError]=useState(false)
  const [customerSearch, setCustomerSearch]=useState('')
  const [customerError, setCustomerError]=useState(false)
  const [projectSearch, setProjectSearch]=useState('')
  const [projectError, setProjectError]=useState(false)

  const { selectedPlant } = useContext(AppContext)

  // Set Customer and project selections requirement
  let settings={ ...Settings};
  if(!settings.hasOwnProperty('sampleForm')) {
      // @ts-ignore
      settings['sampleForm']={selectionOnly: false}
  } else {
    // @ts-ignore
    if(!settings.sampleForm.hasOwnProperty('selectionOnly')) {
      // @ts-ignore
      settings.sampleForm.selectionOnly=false}
    }
  // @ts-ignore
  let selectionOnly=settings.sampleForm.selectionOnly

  let valid=Boolean(data.receiptId && data.recipe!==undefined && data.customer_id && data.project_id && data.order_amount)
  if(!selectionOnly)
    valid=Boolean(data.receiptId && data.recipe!==undefined && (data.customer || data.customer_id) &&
      (data.project || data.project_id) && data.order_amount)

  useEffect(() => {
    if (open) {
      setSearchValue('')
      setProjectSearch('')
      setCustomerSearch('')
      setRecipeError(false)
      setCustomerError(false)
      setProjectError(false)
      setEditOnly(false)
      setData({ testDate: new Date() })
      setManual(false)
      setIsSearchResult(false)
      setSuggestions(filterSuggestions(receipts))
    }
  }, [open, receipts])

  function filterSuggestions(receipts: Receipt[]): Receipt[] {
    let format='YYYY-MM-DDTHH:mm:ssZ';
    let start=moment().startOf('day').format(format);
    let end=moment().subtract(0,'day').endOf('day').format(format);
    let suggestions=receipts.filter(r=>r.date>=start && r.date<=end)
    return suggestions.slice(0, 9)
  }

  useEffect(() => {
    if(!open) return
      getRecipes().then(
          function(response: any) {
            setRecipes(response.data.data)
          }
      )
      getProjects().then(
        function(response: any) {
          setProjects(response.data.data)
        }
      )
      getCustomers().then(
        function(response: any) {
          setCustomers(response.data.data)
        }
      )
  },[open]);

  const handleAdd = useCallback((receipt?: Receipt) => {

    // Set customer name
    if(data.customer_id)
      for(let customer of customers)
        if(customer.id===data.customer_id) {
//          data.customer=customer.name
          break
        }

    // Set project name
    if(data.project_id)
      for(let project of projects)
        if(project.id===data.project_id) {
  //        data.project=project.name
          break
        }

    let hasErrors=false
    setRecipeError(false)
    if(recipeSearch.toLowerCase()!==data.recipe?.recipeName.toLowerCase()) {
      setRecipeError(true)
      hasErrors=true
    }

    if(selectionOnly) {
      // Customer and project selections are required, so validate it

      setCustomerError(false)
      if(data.customer===undefined || data.customer!==undefined && customerSearch.toLowerCase()!==data.customer.toLowerCase()) {
        setCustomerError(true)
        hasErrors=true
      }

      setProjectError(false)
      if(data.project===undefined || data.project!==undefined && projectSearch.toLowerCase()!==data.project.toLowerCase()) {
        setProjectError(true)
        hasErrors=true
      }
    } else {
      // When not selected customer and project can be existing instancies

      if(data.customer!==undefined)
        for(let customer of customers)
          { // @ts-ignore
            if(customer.name.trim().toLowerCase()===data.customer.trim().toLowerCase()) {
              data.customer_id=customer.id
              data.customer=undefined
              break
            }
          }

      if(data.project!==undefined)
        for(let project of projects)
          { // @ts-ignore
            if(project.name.trim().toLowerCase()===data.project.trim().toLowerCase()) {
              data.project_id=project.id
              data.project=undefined
              break
            }
          }
    }

    !hasErrors && onAdd && data.receiptId && onAdd(receipt ? receipt : {
      id: data.receiptId,
      revision: { id: data.recipe?.revision, recipe: data.recipe },
      recipe_id: data.recipe===undefined ? 0 : data.recipe.id,
      plant: selectedPlant,
      date: data.testDate,
      customer: data.customer,
      customer_id: data.customer_id,
      project: data.project,
      project_id: data.project_id,
      amount: data.amount,
      order_amount: data.order_amount,
      remarks: ''
    } as Receipt,receipt ? false : !editOnly)
  }, [onAdd, data, customers, projects, selectedPlant, recipeSearch, projectSearch, customerSearch])

  const getSuggestions = useCallback((value: string) => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length
    setRecipeSearch(inputValue)
    return inputLength === 0 || !recipes.length ? [] : recipes.filter(recipe => testInput(recipe, inputValue))
  }, [recipes])

  function testInput(recipe: any, inputValue: string): any {
    return (recipe.recipeName.toLowerCase().indexOf(inputValue) >= 0) ||
    (recipe.id.toString().indexOf(inputValue) >= 0);
  }

  const renderSuggestion = useCallback((recipe: Recipe, { query, isHighlighted }: Autosuggest.RenderSuggestionParams) => {
    const matches = match(recipe.recipeName, query)
    const parts = parse(recipe.recipeName, matches)
    return (
      <MenuItem selected={isHighlighted} component="div" key={'recipe-'+recipe.id}>
        <div>
          <span key={recipe.id} style={{ fontWeight: 400 }}>{recipe.id} - </span>
          {parts.map(part => (
            <span key={part.text} style={{ fontWeight: part.highlight ? 500 : 400 }}>
              {part.text}
            </span>
          ))}
        </div>
      </MenuItem>
    )
  }, [])

  const getCustomerSuggestions = useCallback((value: string) => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length
    setCustomerSearch(inputValue)
    if(!selectionOnly)
      setData({ ...data, customer: value.trim(), customer_id: undefined })
    return inputLength === 0 || !customers.length ? [] : customers.filter(customer => testCustomerInput(customer, inputValue))
  }, [customers, data])
  function testCustomerInput(customer: any, inputValue: string): any {
    return (customer.name.toLowerCase().indexOf(inputValue) >= 0);
  }

  const renderCustomerSuggestion = useCallback((customer: any, { query, isHighlighted }: Autosuggest.RenderSuggestionParams) => {
    const matches = match(customer.name, query)
    const parts = parse(customer.name, matches)
    return (
      <MenuItem selected={isHighlighted} component="div">
        <div>
          <span key={'customer-'+customer.id} style={{ fontWeight: 400 }}>{customer.name} - </span>
          {parts.map(part => (
            <span key={part.text} style={{ fontWeight: part.highlight ? 500 : 400 }}>
              {part.text}
            </span>
          ))}
        </div>
      </MenuItem>
    )
  }, [])

  const getProjectSuggestions = useCallback((value: string) => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length
    setProjectSearch(inputValue)
    if(!selectionOnly)
      setData({ ...data, project: value.trim(), project_id: undefined })
    return inputLength === 0 || !projects.length ? [] : projects.filter(project => testProjectInput(project, inputValue))
  },[projects, data])
  function testProjectInput(project: any, inputValue: string): any {
    return (project.name.toLowerCase().indexOf(inputValue) >= 0);
  }

  const renderProjectSuggestion = useCallback((project: any, { query, isHighlighted }: Autosuggest.RenderSuggestionParams) => {
    const matches = match(project.name, query)
    const parts = parse(project.name, matches)
    return (
      <MenuItem selected={isHighlighted} component="div">
        <div>
          <span key={'project-'+project.id} style={{ fontWeight: 400 }}>{project.name} - </span>
          {parts.map(part => (
            <span key={part.text} style={{ fontWeight: part.highlight ? 500 : 400 }}>
              {part.text}
            </span>
          ))}
        </div>
      </MenuItem>
    )
  }, [])

  const handleChange = useCallback((key: string, value: string | number | Recipe | Date | any) => {
    if(key==='recipe') {
      setRecipeSearch(value.recipeName)
      setData({...data, [key]: value})
    } else if (key==='customer') {
      setCustomerSearch(value.name)
      setData({ ...data, customer: value.name, customer_id: value.id })
    } else if (key==='project') {
      setProjectSearch(value.name)
      setData({ ...data, project: value.name, project_id: value.id })
    } else
      setData({ ...data, [key]: value })
  }, [data])

  const handleSearchChange = useCallback((value: string) => {
    setSearchValue(value)
    setData({ ...data, receiptId: Number(value) })
    if (value === '') {
      setSuggestions(filterSuggestions(receipts))
      setIsSearchResult(false)
    } {
      handleSearch(value)
    }
  }, [receipts, data])

  const handleSearch = useCallback(async (value: string) => {
    if(value.length>14) return  // Receipt ID is too big
    let receipt_id=parseInt(value)
    if(isNaN(receipt_id) || receipt_id<=0 ) return // Invalid receipt ID
    setSearchValue(value)
    // @ts-ignore
    getOne(selectedPlant.id, receipt_id).then(
        function(response: any) {
          const result=response.data.data;
          result ? setSuggestions([result]) : setSuggestions([])
          setIsSearchResult(true)
        }
    ).catch(function (error: any) {
        if(error.response) {
          setSuggestions(filterSuggestions(receipts))
          setIsSearchResult(false)
        }
      }
    )
  }, [receipts, selectedPlant])

  function copyData(receipt: Receipt) {
    return {
      receiptId: receipt.id,
      testDate: new Date(),
      recipe_id: receipt.revision.recipe.id,
      recipe: receipt.revision.recipe,
      customer: receipt.customer,
      customer_id: receipt.customer_id,
      project: receipt.project,
      project_id: receipt.project_id,
      amount: receipt.amount,
      order_amount: receipt.order_amount,
    }
  }

  return (
    <Dialog open={open} TransitionComponent={Transition} onEntered={(ref: HTMLElement) => ref.removeAttribute('tabindex')}>
      <DialogTitle onClose={onClose}>Monstername {editOnly ? 'wijzigen' : 'toevoegen'}</DialogTitle>
      <DialogContent>
        {!manual ? (
          <Fragment>
            <SearchField searchValue={searchValue} placeholder="Zoek bonnummer" onSearch={handleSearch} onChange={handleSearchChange} />
            {!isSearchResult && (
              <Grid container={true} justify="center" style={{ marginTop: 16 }}>
                <Button variant="contained" color="primary" onClick={() => {
                  setEditOnly(false); setManual(true)}}>Handmatig invoeren</Button>
              </Grid>
            )}
            <Typography variant="body2" gutterBottom={true} color={!isSearchResult || suggestions.length > 0 ? 'initial' : 'error'} component="p" style={{ marginTop: 16 }}>
              {isSearchResult ? suggestions.length > 0 ? 'Zoek resultaat:' : 'Bon niet gevonden' : suggestions.length > 0 ? 'Recente bonnen:' : ''}
            </Typography>
            {!isSearchResult && suggestions.length > 0 && (
              <Grid item={true} key={123456}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Bonnummer</TableCell>
                      <TableCell>Hoeveelheid</TableCell>
                      <TableCell>Getest</TableCell>
                      <TableCell> </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {suggestions.map((receipt, k) => (
                    <TableRow key={receipt.id}>
                      <TableCell>{receipt.id}</TableCell>
                      <TableCell>{receipt.amount.toString().replace('.',',')} m3</TableCell>
                      <TableCell style={{textAlign: 'center'}}>
                        {receipt.cubeTest && <span><Icon>check</Icon></span>}
                      </TableCell>
                      <TableCell><Button variant="contained" color="primary" onClick={() => {
                        setSearchValue(receipt.id.toString());
                        handleSearch(receipt.id.toString())
                      }}><Icon>search</Icon></Button>
                      </TableCell>
                    </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            )}
            {isSearchResult && suggestions.length > 0 && suggestions.map((receipt, k) => (
              <Grid item={true} key={k}>
                  <Table size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell>Recept:</TableCell>
                        <TableCell>{receipt.revision.recipe.recipeName ? receipt.revision.recipe.recipeName : ''}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Klant:</TableCell>
                        <TableCell>{receipt.customer ? receipt.customer : ''}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Werk:</TableCell>
                        <TableCell>{receipt.project ? receipt.project : ''}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Order kuub:</TableCell>
                        <TableCell>{receipt.order_amount ? receipt.order_amount + ' m3' : ''}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Aantal kuub:</TableCell>
                        <TableCell>{receipt.amount ? receipt.amount + ' m3' : ''}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
              </Grid>
            ))}
          </Fragment>
        ) : (
            <Fragment>
              <TextField
                label="Bonnummer"
                variant="outlined"
                margin="normal"
                fullWidth={true}
                inputProps={{ maxLength: 15 }}
                value={data.receiptId || ''}
                disabled={editOnly}
                onChange={e => handleChange('receiptId', Number(e.target.value))}
              />
              <Autocomplete
                value={data.recipe ? data.recipe.recipeName : ''}
                label="Kies een recept"
                variant="outlined"
                margin="normal"
                fullWidth={true}
                onChange={(recipe) => handleChange('recipe', recipe)}
                getSuggestions={getSuggestions}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                disabled={editOnly}
              />
              {recipeError && <Alert severity={'error'}>Selecteer svp een recept</Alert>}
              <Autocomplete
                value={data.customer ? data.customer : ''}
                label={selectionOnly ? "Selecteer een klant" : "Selecteer een klant of vul een kantnaam in"}
                variant="outlined"
                margin="normal"
                required={true}
                fullWidth={true}
                onChange={(customer) => handleChange('customer', customer)}
                getSuggestions={getCustomerSuggestions}
                getSuggestionValue={getCustomerSuggestionValue}
                renderSuggestion={renderCustomerSuggestion}
                disabled={editOnly}
              />
              {customerError && <Alert severity={'error'}>Selecteer svp een klant</Alert>}
              <Autocomplete
                value={data.project ? data.project : ''}
                label={selectionOnly ? "Selecteer een werk" : "Selecteer een werk of vul een werk in"}
                variant="outlined"
                margin="normal"
                fullWidth={true}
                onChange={(project) => handleChange('project', project)}
                getSuggestions={getProjectSuggestions}
                getSuggestionValue={getProjectSuggestionValue}
                renderSuggestion={renderProjectSuggestion}
                disabled={editOnly}
              />
              {projectError && <Alert severity={'error'}>Selecteer svp een project</Alert>}
              <NumericTextField
                label="Order kuub"
                margin="normal"
                required={true}
                fullWidth={true}
                value={typeof data.order_amount !== 'undefined' && data.order_amount !== null ? data.order_amount : ''}
                onChange={e => handleChange('order_amount', Number(e.target.value))}
                maximumFractionDigits={2}
              />
              <NumericTextField
                label="Aantal kuub"
                margin="normal"
                fullWidth={true}
                value={typeof data.amount !== 'undefined' && data.amount !== null ? data.amount : ''}
                onChange={e => handleChange('amount', Number(e.target.value))}
                maximumFractionDigits={2}
                disabled={editOnly}
              />
              <MobileDatePicker
                label="Datum monstername"
                value={data.testDate}
                onChange={e => handleChange('testDate', e ? e : new Date())}
                renderInput={({ helperText, ...props }) => <TextField {...props} fullWidth={true} variant="outlined" margin="normal" />}
                inputFormat="D MMMM YYYY"
                maxDate={new Date()}
                disableCloseOnSelect={false}
                cancelText="Annuleren"
                disabled={editOnly}
              />
            </Fragment>
          )}
      </DialogContent>
      <DialogActions>
        {manual && <Button onClick={() => { setManual(false); handleSearchChange('') }}>Terug</Button>}
        <span style={{ flex: 1 }} />
        <Button onClick={onClose}>Annuleren</Button>
        {!manual && <Button onClick={() => { // @ts-ignore
          setData(copyData(suggestions[0]));
          setEditOnly(true);
          setManual(true)
        }} disabled={!suggestions.length} color="secondary">Toevoegen</Button>}

        { /*
        {!manual && <Button onClick={() => { // @ts-ignore
          setData(copyData(suggestions[0]));
          setEditOnly(false);
          setManual(true)
        }} disabled={!suggestions.length} color="secondary">Toevoegen met overname</Button>}
        */ }

        {manual && <Button onClick={() => handleAdd()} disabled={!valid} color="secondary">{editOnly ? 'Opslaan' : 'Toevoegen'}</Button>}
      </DialogActions>
    </Dialog>
  )
}

export default AddSampleDialog
